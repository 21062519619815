import React, { useState } from "react";
import Link from "next/link";
import Meta from "../../components/Meta";
import { useRouter } from "next/router";
import axios from "axios";
import ForgotPassModal from "../../components/modal/ForgotPassModal";
import Image from "next/image";
import { signIn } from "next-auth/react";
import { getSession } from "next-auth/react";

const Login = ({ session }) => {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleLoginWithGoogle = async () => {
    try {
      const response = await signIn("google");

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Invalid email format");
      return;
    }
    // Check if the password is at least 8 characters long
    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }
    // Check if the password is more than 50 characters long
    if (password.length > 50) {
      setError("Password cannot be more than 50 characters long");
      return;
    }

    // Check if password contains a space
    if (password.includes(" ")) {
      setError("Password cannot contain spaces");
      return;
    }
    // Check if password contains at least one number, one uppercase letter, one lowercase letter, one special character
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;
    if (!passwordPattern.test(password)) {
      setError(
        "Password must contain at least one number, one uppercase letter, one lowercase letter, one special character"
      );
      return;
    }

    setIsSigningIn(true);
    const result = await signIn("credentials", {
      redirect: false,
      email,
      password,
    });

    if (result?.error) {
      setError(result.error);
    } else {
      setError("");
      const session = await getSession();

      if (session) {
        // once logged in redirect to home
        router.push("/");
      }
    }
    setIsSigningIn(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // const [itemActive, setItemActive] = useState(1);
  // const tabItem = [
  //   {
  //     id: 1,
  //     text: "Ethereum",
  //     icon: "ETH",
  //   },
  //   {
  //     id: 2,
  //     text: "Torus",
  //     icon: "torus",
  //   },
  //   { id: 4, text: "Mobile Wallet", icon: "mbl-wallet" },
  // ];

  return (
    <div className="w-full min-h-[100svh] bg-[#000000]">
      <Meta title="SignIn" />
      <Link href={"/"}>
        <Image
          src="/logo.png"
          alt="A gif of a person dancing"
          width={500}
          height={500}
          className="fixed top-3 left-3 sm:top-6 sm:left-6 w-[100px] h-auto z-[1] object-cover"
        />
      </Link>

      {/* <Image
        src="/giphy.webp"
        alt="A gif of a person dancing"
        width={500}
        height={500}
        className="fixed top-0 left-0 w-full h-[100vh] z-[-1] object-cover"
        priority
      /> */}
      <ForgotPassModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex justify-center items-center pt-12 text-white min-h-[100svh] mx-4 lg:mx-0">
        <div className="w-full max-w-[52rem]">
          <h1 className="text-4xl font-bold text-center mb-4">Sign In</h1>
          <p className="text-l font-semibold text-center mb-8">Welcome Back</p>
          <form
            onSubmit={handleSubmit}
            className="rounded-2xl px-12 pt-8 pb-10 mb-24 border border-[#3c3c3c] shadow-lg"
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none text-white border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline bg-[#171716] outline-none border-none"
                id="email"
                type="email"
                placeholder="example@website.com"
                value={email}
                autoCapitalize="none"
                onChange={handleEmailChange}
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-[white] text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-white mb-3 leading-tight focus:outline-none focus:shadow-outline bg-[#171716] outline-none border-none"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="min. 8 characters"
                  value={password}
                  autoCapitalize="none"
                  onChange={handlePasswordChange}
                />
                {/* Toggle Password Visibility Icon */}
                <span
                  className="absolute right-0 top-[-3px] mr-4 mt-3 cursor-pointer text-white"
                  onClick={togglePasswordVisibility}
                >
                  {!showPassword ? (
                    <svg
                      className="h-5 w-5"
                      viewBox="0 0 192 164"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M107.156 29.7626C103.494 29.3664 99.7723 29.1394 96 29.0829C79.516 29.156 62.4283 33.1656 46.2539 40.8487C34.2446 46.7882 22.5462 55.1733 12.3867 65.5165C7.39712 70.7965 1.02896 78.4417 0 86.5985C0.1216 93.6644 7.70448 102.386 12.3867 107.681C21.9136 117.618 33.3074 125.763 46.2539 132.349C46.694 132.562 47.1354 132.773 47.5781 132.981L35.5666 153.958L51.8874 163.603L140.114 9.99813L124.404 0.396851L107.156 29.7626ZM144.41 40.2392L132.422 61.0164C137.937 68.1815 141.211 77.0173 141.211 86.5985C141.211 110.48 120.967 129.841 95.9882 129.841C94.9083 129.841 93.8618 129.724 92.8006 129.653L84.867 143.388C88.5259 143.779 92.2254 144.065 95.9998 144.114C112.5 144.04 129.578 139.984 145.734 132.349C157.744 126.409 169.454 118.024 179.613 107.681C184.603 102.401 190.971 94.7556 192 86.5985C191.878 79.5327 184.295 70.8114 179.613 65.5164C170.086 55.5794 158.681 47.4343 145.734 40.8484C145.297 40.6365 144.85 40.446 144.41 40.2392ZM95.9883 43.3565C97.0837 43.3565 98.1704 43.4004 99.2461 43.4736L89.9531 59.5634C76.9109 62.2076 67.125 73.3042 67.125 86.5869C67.125 89.9236 67.7395 93.118 68.871 96.0789C68.8723 96.0823 68.8698 96.0874 68.871 96.0908L59.5546 112.227C54.0264 105.056 50.7654 96.1908 50.7654 86.5983C50.7656 62.7175 71.0093 43.3564 95.9883 43.3565ZM123.023 77.2821L102.082 113.563C115.054 110.869 124.77 99.8234 124.77 86.5869C124.77 83.3111 124.116 80.1967 123.023 77.2821Z" />
                    </svg>
                  ) : (
                    <svg
                      className="w-5 h-5"
                      viewBox="0 0 181 120"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M90.5116 36.3771C83.9745 36.3771 77.7051 38.8719 73.0827 43.3126C68.4602 47.7533 65.8634 53.7762 65.8634 60.0563C65.8634 66.3365 68.4602 72.3594 73.0827 76.8001C77.7051 81.2408 83.9745 83.7356 90.5116 83.7356C97.0487 83.7356 103.318 81.2408 107.94 76.8001C112.563 72.3594 115.16 66.3365 115.16 60.0563C115.16 53.7762 112.563 47.7533 107.94 43.3126C103.318 38.8719 97.0487 36.3771 90.5116 36.3771ZM90.5116 99.5217C79.6164 99.5217 69.1674 95.3638 61.4634 87.9626C53.7593 80.5614 49.4313 70.5232 49.4313 60.0563C49.4313 49.5895 53.7593 39.5513 61.4634 32.1501C69.1674 24.7489 79.6164 20.591 90.5116 20.591C101.407 20.591 111.856 24.7489 119.56 32.1501C127.264 39.5513 131.592 49.5895 131.592 60.0563C131.592 70.5232 127.264 80.5614 119.56 87.9626C111.856 95.3638 101.407 99.5217 90.5116 99.5217ZM90.5116 0.858276C49.4313 0.858276 14.3487 25.4057 0.134888 60.0563C14.3487 94.7069 49.4313 119.254 90.5116 119.254C131.592 119.254 166.674 94.7069 180.888 60.0563C166.674 25.4057 131.592 0.858276 90.5116 0.858276Z" />
                    </svg>
                  )}
                </span>
              </div>
            </div>
            {error && <p className="text-[#ff3f3f] text-xs italic">{error}</p>}
            <div className="flex flex-col items-end justify-end w-full  mb-4">
              <div
                className="inline-block align-baseline font-bold cursor-pointer text-sm text-blue-500 hover:text-blue-800"
                onClick={() => setIsOpen(true)}
              >
                Forgot Password?
              </div>
            </div>
            {isSigningIn ? (
              <div className="spin-loader mx-auto"></div>
            ) : (
              <button
                className="btn-grad hover:opacity-95 transition-opacity font-bold py-4 w-full rounded-xl focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Sign In
              </button>
            )}
            <h6 className="text-center mt-4 mb-4 text-sm">or </h6>
            <div className="flex justify-center items-center">
              <button
                onClick={handleLoginWithGoogle}
                className="flex justify-center items-center gap-2 hover:bg-accent transition-colors border-[1px] border-[grey] font-bold py-1 px-4 lg:px-24 lg:py-3 rounded focus:outline-none focus:shadow-outline"
              >
                {" "}
                <img src="/images/google.png" alt="" className="w-6" /> Sign in
                with google
              </button>
            </div>
            <h6 className="text-center mt-4 mb-4 text-sm">
              Don&apos;t have an account?{" "}
              <Link href="/signup">
                <span className="font-bold  cursor-pointer">Sign up</span>
              </Link>
            </h6>
          </form>
        </div>
      </div>
    </div>
  );
};
export async function getServerSideProps(context) {
  const session = await getSession(context);
  // if user already logged in then redirect to home
  if (session?.user?.userDetails) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: { session },
  };
}

export default Login;
