import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import DisableShield from "../loading/DisableShield";

const ForgotPassModal = ({ isOpen, setIsOpen }) => {
  const [isSpinner, setIsSpinner] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [coolDown, setCoolDown] = useState(null);
  const [formattedTime, setFormattedTime] = useState("01:00");
  const [resendDisabled, setResendDisabled] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleOnOtpSend = async (e) => {
    if (email === "") {
      setError("Email is required!");
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Invalid email format");
      return;
    }
    setResendDisabled(true);
    try {
      const res = await axios.post("/api/users/forgot-pass", {
        email,
      });
      if (res.status === 200) {
        alert("OTP sent successfully");
        setError("");
        setIsOtpSent(true);
        setCoolDown(60000);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
    }
    setResendDisabled(false);
  };

  useEffect(() => {
    if (coolDown >= 0) {
      const interval = setInterval(() => {
        setCoolDown((prevTimeout) => prevTimeout - 1000);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [coolDown]);

  useEffect(() => {
    const FormatTime = (time) => {
      const minutes = Math.floor(time / 60000)
        .toString()
        .padStart(2, "0");
      const seconds = ((time % 60000) / 1000)
        .toFixed(0)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    };
    const time = FormatTime(coolDown);
    if (coolDown < 0) {
      setFormattedTime(null);
    } else setFormattedTime(time);
  }, [coolDown]);

  const handleOnPassChange = async (e) => {
    e.preventDefault();
    // if (coolDown && (new Date() - new Date(coolDown)) / 1000 < 300) {
    //   setError("Please wait 5 minutes before sending another request!");
    //   return;
    // }
    // check password
    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }
    // Check if the password is more than 50 characters long
    if (password.length > 50) {
      setError("Password cannot be more than 50 characters long");
      return;
    }

    // Check if password contains a space
    if (password.includes(" ")) {
      setError("Password cannot contain spaces");
      return;
    }
    // Check if password contains at least one number, one uppercase letter, one lowercase letter, one special character
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;
    if (!passwordPattern.test(password)) {
      setError(
        "Password must contain at least one number, one uppercase letter, one lowercase letter and one special character"
      );
      return;
    }
    // compare with confirmPassward
    if (password !== confirmPassword) return setError("Passwords do not match");

    if (email === "") {
      setError("Email is required!");
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Invalid email format");
      return;
    }
    if (!otp || otp > 999999 || otp < 100000) {
      setError("Otp should be in the range 100000 - 999999");
      return;
    }
    setIsSpinner(true);
    try {
      const res = await axios.post("/api/users/reset-pass", {
        otp,
        email,
        password,
      });
      if (res.status === 200) {
        setError("");
        alert(
          "Password Changed Successfully! Please login with your new password"
        );
        setIsOpen(false);
      } else {
        setError(res.data.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
    }
    setIsSpinner(false);
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-[1] flex justify-center items-start bg-[#111111] bg-opacity-80 pt-24">
      <form className="bg-[#111111] relative border border-[#3c3c3c] rounded-lg shadow-lg p-6 w-full max-w-xl mx-8">
        <div className="cross-btn absolute top-2 right-2">
          <button
            onClick={() => setIsOpen(false)}
            className="focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>

        <div className="mt-8">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email Address
          </label>
          <div className="flex justify-between items-center gap-2">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-[#111111] rounded w-full py-2 px-3 text-gray-700 border border-[#3c3c3c] outline-none focus:outline-none"
              placeholder="Enter your email"
            />
            {coolDown <= 0 &&
              (resendDisabled ? (
                <div className="flex justify-center items-center w-[110px]">
                  {" "}
                  <div className="mini-loader"></div>
                </div>
              ) : (
                <div
                  className="cursor-pointer bg-accent text-white rounded-sm py-2 px-3 w-[110px]"
                  onClick={handleOnOtpSend}
                >
                  send otp
                </div>
              ))}
            {coolDown > 0 && (
              <div className="w-[110px] italic text-accent">
                try again in {formattedTime}
              </div>
            )}
          </div>
        </div>
        {isOtpSent && (
          <div className="">
            <div className="mt-8">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                New Password:
              </label>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter new password"
                className="bg-[#111111] rounded w-full py-2 px-3 text-gray-700 border border-[#3c3c3c] outline-none focus:outline-none"
                required
              />
            </div>
            <div className="mt-8">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Confirm Password:
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm new password"
                className="bg-[#111111] rounded w-full py-2 px-3 text-gray-700 border border-[#3c3c3c] outline-none focus:outline-none"
                required
              />
            </div>
            <div className="mt-8">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="text"
              >
                Enter OTP
              </label>
              <input
                type="number"
                id="otp"
                maxLength={999999}
                minLength={100000}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="bg-[#111111] rounded w-full py-2 px-3 text-gray-700 border border-[#3c3c3c] outline-none focus:outline-none"
                placeholder="Enter your 6 digit otp"
              />
            </div>
          </div>
        )}
        {error && <p className="text-[#ff3f3f] text-xs italic p-2">{error}</p>}
        {success && (
          <p className="text-[#00ff00] text-xs italic p-2">{success}</p>
        )}
        {isOtpSent && (
          <div className="flex items-center justify-center">
            {isSpinner ? (
              <div>
                <DisableShield />

                <div className="spin-loader mt-4"></div>
              </div>
            ) : (
              <button
                type="submit"
                className="bg-blue-500 mt-4 w-full rounded-full cursor-pointer text-white text-md btn-grad font-semibold py-3"
                onClick={(e) => handleOnPassChange(e)}
              >
                Submit
              </button>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default ForgotPassModal;
